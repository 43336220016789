<template>
  <div class="login login-h5" v-wechat-title="title" :style="{ height: bodyHeight + 'px' }">
    <div class="proj_box">
      <div class="proj_title">参与项目</div>
      <div class="proj_name">{{ proj_name }}</div>
    </div>
    <van-form @submit="onSubmit" class="form-container" v-if="isChange">
      <div class="title">
        <div class="login-title">医生登录</div>
        <div class="description">欢迎登录医学健康数据平台</div>
      </div>
      <!-- color="#849EFB" -->
      <div class="form">
        <div class="password">
          <van-field v-model="mobile" name="mobile" @click="showHight()" placeholder="请输入手机号码" class="filed" clearable>
            <template #left-icon>
              <img src="/static/mobile.png" class="icon" />
            </template>
          </van-field>
          <van-field v-model="idsn" @click="showHight()" name="idsn" placeholder="请输入身份证号码" class="filed" clearable>
            <template #left-icon>
              <img src="/static/tabMe.png" class="icon" />
            </template>
          </van-field>
        </div>
      </div>
      <!-- <div class="tips">未注册的手机号验证后自动创建账号</div> -->
      <div style="margin-top: 16px">
        <van-button round block type="info" native-type="submit" class="login_btn" color="#4B99FF" :disabled="btnDisabled">下一步</van-button>
      </div>
    </van-form>
    <!-- <changePassWord v-else @goforget="goforget" :title="title"></changePassWord> -->

    <div class="img">
      <!-- <img src="/static/login.png" /> -->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Dialog, Toast } from 'vant'
import { Children } from './login.js'
import { loginByNew, getList, getCurrentUser } from '@/api/api'
import changePassWord from './changePassWord.vue'
import { checkCellphone, isIdCardNo } from '@/utils/utils'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  mixins: [Children],
  components: {
    changePassWord
  },
  name: '',
  data() {
    return {
      password: '',
      idsn: '',
      isChange: true,
      title: '医学健康数据平台',
      project_id: null,
      closed: false, //项目已关闭
      projList: [], //项目列表
      proj_name: '', //项目名称
      btnDisabled: false,
      bodyHeight: null //页面高度
    }
  },
  mounted() {
    if (!localStorage.getItem('project_id')) {
      if (location.hostname.indexOf('.papv2.sungotech.com') >= 0) {
        location.href = 'http://ecase.papwxtest.sungotech.com/?#/menuIndex'
      } else {
        location.href = 'https://ecase.papwx.cpah.net.cn/?#/menuIndex'
      }
      return
    }
    // 防止安卓软键盘压缩背景
    this.bodyHeight = document.documentElement.clientHeight - 45
    console.log('bodyHeight', this.bodyHeight)
    console.log('----login--', this.isChange)
    this.project_id = localStorage.getItem('project_id')
    console.log('this.project_id', this.project_id)
    // if (this.registerInfo) this.goforget(false, '用户注册')
    this.init()
  },
  computed: {
    ...mapState(['registerInfo', 'ifClosed', 'userInfo'])
  },
  methods: {
    ...mapMutations(['setClosePop']),
    ...mapActions(['SET_USER_INFO', 'SET_USER_ID', 'SET_DOCTOR_INFO', 'GET_IF_CLOSED', 'SET_PROJ_NAME']),
    async init() {
      await getList()
        .then(res => {
          console.log('res项目列表inLogin', res)
          this.projList = res
          for (let i in this.projList) {
            if (this.projList[i].id == this.project_id) {
              this.proj_name = this.projList[i].name
            }
          }
          this.SET_PROJ_NAME(this.proj_name)
          console.log('项目名：', this.proj_name)
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    showHight() {
      console.log('点击输入框，查看页面高度', document.documentElement.clientHeight)
    },
    async onSubmit(values) {
      let data = {}
      if (!this.mobile || !this.idsn) {
        Toast('请完善信息')
        return false
      } else if (!checkCellphone(this.mobile)) {
        Toast('请输入正确的手机号码')
        return false
      } else if (!isIdCardNo(this.idsn)) {
        Toast('请输入正确的身份证号码')
        return false
      }
      data = {
        mobile: this.mobile,
        idsn: this.idsn,
        project_id: this.project_id
      }

      try {
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true
        })
        this.btnDisabled = true
        let res = await loginByNew(data)

        console.log('res', res)
        axios.defaults.auth = {
          username: res
        }
        await this.SET_USER_INFO({
          userInfo: {
            access_token: res
          }
        })
        await this.$router.push({
          path: '/login',
          query: { mobile: this.mobile }
        })
        this.btnDisabled = false
        Toast.clear()
        // this.GET_IF_CLOSED().then(() => {
        //   if (this.ifClosed) this.setClosePop(true) //打开项目关闭弹框
        // })
        // await getCurrentUser({
        //   access_token: res
        // }).then(response => {
        //   console.log('getCurrentUser', response)
        //   this.SET_USER_INFO({ userInfo: response })
        //   if (response.doctor) {
        //     this.SET_USER_ID(response.doctor.id)
        //     this.SET_DOCTOR_INFO(response.doctor)
        //     this.$router.replace({
        //       path: '/me'
        //     })
        //   } else {
        //     this.goforget(false, '用户注册')
        //   }
        // })
      } catch (err) {
        this.btnDisabled = false
        Toast(err.response.data.message)
        console.log(err)
        console.log('登录失败')
      }
    }
    // goforget(e, title) {
    //   if (title == '用户注册' && this.proj_name == '例例生辉病例调研') {
    //     Dialog.alert({
    //       title: '温馨提示',
    //       message: '项目调研已达上限，感谢您的参与',
    //       confirmButtonColor: '#4B99FF'
    //     })
    //     return
    //   }
    //   this.isChange = e
    //   if (e) {
    //     this.title = '医学健康数据平台'
    //   } else {
    //     this.title = title
    //   }
    // }
  }
}
</script>

<style src="./login.scss" lang="scss"></style>
<style lang="scss">
.login-h5 {
  .icon {
    width: 14px;
    height: 14px;
    object-fit: contain;
    margin-right: 8px;
  }
}
</style>
