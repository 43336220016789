<template>
  <div class="change" v-wechat-title="title">
    <van-form @submit="onSubmit" class="form">
      <!-- <div class="top">忘记密码</div> -->
      <van-field v-model="mobile" name="mobile" @click="showHight()" placeholder="请输入手机号码" class="filed">
        <!-- <template #left-icon>
                    <img src="/static/mobile.png" class="icon" />
                </template> -->
      </van-field>
      <van-field v-model="code" name="code" placeholder="请输入验证码" class="filed">
        <!-- <template #left-icon>
                    <img src="/static/code.png" class="icon" />
                </template> -->
        <template #extra>
          <span class="fieldRemark" @click="getCode">{{ btnText }}</span>
        </template>
      </van-field>
      <van-field v-model="newPassword" name="newPassword" placeholder="设置8-18位密码" class="filed" type="password">
        <!-- <template #left-icon>
                    <img src="/static/code.png" class="icon" />
                </template> -->
      </van-field>
      <van-field v-model="rePassword" name="rePassword" placeholder="确定密码" class="filed" type="password">
        <!-- <template #left-icon>
                    <img src="/static/code.png" class="icon" />
                </template> -->
      </van-field>
      <div style="margin: 16px 0">
        <van-button round block type="info" native-type="submit" :disabled="btnDisabled" class="up">{{ btn_text }}</van-button>
      </div>
    </van-form>
    <div class="goBack" @click="back">
      <img src="/static/goBack.png" />
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { checkCellphone } from '@/utils/utils'
import { Children } from './login.js'
import { modifyPassword, login, registerFirst, getOtherdoctorInfo } from '@/api/api'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  mixins: [Children],
  name: '',
  props: {
    title: ''
  },
  data() {
    return {
      mobile: '',
      code: '',
      newPassword: '',
      rePassword: '',
      btn_text: '',
      btnDisabled: false
    }
  },
  mounted() {
    this.title == '密码找回' ? (this.btn_text = '更新') : (this.btn_text = '注册')
    if (this.$route.query.mobile) {
      this.mobile = this.$route.query.mobile
    }
    console.log('---changePassWord----')
    console.log('this.registerInfo', this.registerInfo)
    //注册信息回显
    // if(this.registerInfo){
    //     this.mobile=this.registerInfo.mobile;
    //     this.code=this.registerInfo.code;
    //     this.newPassword=this.registerInfo.newPassword;
    //     this.rePassword=this.registerInfo.rePassword;
    // }
    this.$store.commit('clearRegisterInfo')
  },
  computed: {
    ...mapState(['registerInfo'])
  },
  methods: {
    ...mapActions(['SET_USER_ID', 'SET_DOCTOR_INFO']),
    async onSubmit(values) {
      if (!this.mobile || !this.code || !this.newPassword || !this.rePassword) {
        Toast('请完善资料')
        return false
      } else if (!checkCellphone(this.mobile)) {
        Toast('请输入正确的手机号')
        return false
      } else if (this.newPassword.length > 18 || this.newPassword.length < 8) {
        Toast('请输入8-18位数的密码')
        return false
      } else if (this.newPassword != this.rePassword) {
        Toast('请输入相同的密码')
        return false
      }
      this.btnDisabled = true
      let data = {
        mobile: this.mobile,
        code: this.code,
        password: this.newPassword,
        rePassword: this.rePassword,
        projectId: localStorage.getItem('project_id')
      }
      if (this.title == '用户注册') {
        console.log('用户注册')
        await registerFirst({
          mobile: this.mobile,
          projectId: localStorage.getItem('project_id'),
          password: this.newPassword
        })
          .then(res => {
            this.$store.commit('setRegisterInfo', data)
            this.btnDisabled = false
            data.phone = this.mobile
            if (res) {
              getOtherdoctorInfo({ projectId: localStorage.getItem('project_id'), mobile: this.mobile }).then(res => {
                console.log(res, 'resresgfdewfdsewrfdsawe')
                this.$store.commit('setOtherDoctorInfo', res)
                if (res.status === '等待审核') {
                  this.$router.replace({
                    name: 'Checking'
                  })
                } else {
                  this.$router.replace({
                    name: 'regOcr',
                    query: data
                  })
                }
              })
            } else {
              this.$router.replace({
                name: 'regOcr',
                query: data
              })
            }
          })
          .catch(err => {
            console.log('错误信息', err)
            Toast(err.response.data.message)
            this.btnDisabled = false
          })
      } else if (this.title == '密码找回') {
        console.log('密码找回')
        modifyPassword({
          mobile: this.mobile,
          code: this.code,
          password: this.newPassword,
          rePassword: this.rePassword,
          project_id: localStorage.getItem('project_id')
        })
          .then(() => {
            Toast('修改成功')
            this.back()
            // login({
            //     mobile: this.mobile,
            //     code: this.code,
            //     projectId: localStorage.getItem("project_id")
            // }).then(res => {
            //     console.log("res",res)
            //     this.SET_USER_ID(res.id)
            //     this.SET_DOCTOR_INFO(res)
            //     this.$router.replace({
            //         path: '/me'
            //     })
            // }).catch(err=>{
            //     Toast(err.response.data.message)
            //     console.log(err);
            //     console.log("登录失败")
            // })
          })
          .catch(err => {
            console.log(err.response.data.message, 'err')
            Toast(err.response.data.message)
          })
      } else {
        console.log('无法识别this.title', this.title)
      }
    },
    back() {
      this.$emit('goforget', true)
    },
    showHight() {
      console.log('点击输入框，查看页面高度', document.documentElement.clientHeight)
    }
  }
}
</script>

<style lang="scss" scoped>
.change {
  // width: 80%;
  width: 90%;
  max-width: 500px;
  margin-top: 15vw;

  // margin-top: 10px;
  .fieldRemark {
    color: rgb(61, 130, 255);
    font-size: 14px;
  }

  .filed {
    width: 100%;
    padding: 14px 16px;
    font-size: 14px;
  }

  .up {
    // height: 50px;
    color: #fff;
    font-size: 15px;
    font-family: PingFangSC-Medium;
    background: #4b99ff;
  }
}
</style>
